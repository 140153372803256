.setup-data-lake {
  .agreement {
    span {
      color: #2686cb !important;
    }
  }
}

.region-warning {
  padding: 7px 10px;
  border: 1px solid #8f000e;
  border-radius: 4px;
  position: relative;

  &::before {
    content: 'Note: ';
    color: #8f000e;
  }

  &::after {
    content: '';
    border: 1px solid #8f000e;
    border-right: none;
    border-bottom: none;
    background: #fff;
    transform: rotate(45deg);
    width: 8px;
    height: 8px;
    display: inline-block;
    position: absolute;
    top: -5px;
    right: 100px;
  }
}

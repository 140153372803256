.prisma-help {
  padding: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #333333;

  a {
    margin-left: 10px;
    color: #0a6c8c !important;
    font-weight: bold;
  }

  .pan-icon {
    color: #0a6c8c !important;
  }
}

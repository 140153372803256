@import "../../../common";
// $checkCirclePath: "../../../images/svg-icons/check-circle-solid.svg";

.customer-account-selection {
  height: 300px;

  .activation-left {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 25px;
    margin-bottom: 10px;

    .selected-account {
      color: #42a5ed;
      font-size: 11px;

      > span {
        color: gray;
        margin-right: 5px;
      }
    }
  }

  .support-account-wrapper {
    overflow-y: auto;
    flex: 1;
    margin-left: 10px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;

    &:before {
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 100%, rgba(9, 9, 121, 1) 0%);
      content: '';
      position: absolute;
      height: 10px;
      width: 100%;
      top: 16px;
    }

    > ul {
      border: none;
      .msp-name {
        background: transparent !important;
        margin-left: 5px;
      }
      .ant-menu-item-selected {
        color: white !important;
        background-color: #5482b7 !important;

        .default-account {
          color: white !important;
        }
      }

      .ant-menu-item {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        color: #333333;
        display: flex;

        &.federal-account::before {
          height: 21px;
          line-height: 16px;
          top: 10px;
        }

        .default-account {
          text-align: end;
          flex: 1;
          background-color: unset;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          text-transform: uppercase;
          color: #2686cb;
        }
      }

      :nth-child(even) {
        background-color: #f9f9f9;
      }
    }
  }

  .activation-menu-item-wrapper {
    margin: 13px 0;
    overflow-y: auto;
    @extend .scroll-container;
    border: 2px solid #c4c4c4;
    border-radius: 4px;

    //> div {
    //  height: 90px;
    //}
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.license-type-counter-container {
  display: flex;
  flex-wrap: wrap;
}

.license-type-counter {
  display: flex;
  margin: 5px 0 5px 5px;
  border: 1px solid #d3e8fc;
  border-right: none;

  .license-type-value {
    padding: 3px;
  }

  .license-quantity {
    background-color: #d3e8fc;
    margin-left: 5px;
    padding: 3px;
  }
}

.license-type-tooltip {
  .ant-tooltip-inner {
    font-size: 12px;
    line-height: 16px;
    color: #333333;
    align-items: center;
    background-color: #fafafa;
  }

  .ant-tooltip-arrow {
    border-right-color: #fafafa;
  }

  .license-tooltip-title {
    .label {
      padding-top: 4px;
    }

    .license-groups {
      padding-bottom: 5px;

      .ghost-label {
        font-size: 10px;
        color: #a3a3a3;
        line-height: 18px;
      }

      .license-term {
        padding-bottom: 2px;
      }
    }
  }
}

.license-term {
  padding: 2px;
}

.license-version {
  display: flex;
  margin: 10px 2px;
  font-size: 11px;
}

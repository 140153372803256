.setup-data-lake-view-only {
  padding: 12px;
  margin: 10px 0;
  label {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  .sub-title {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
  }
  p {
    font-size: 12px;
    flex: 1;
    line-height: 14px;
  }
}

.ctrl-toggle {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 16px;
  background-color: #707070;
  border-radius: 32px;
  vertical-align: middle;
  border: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
  user-select: none;
  appearance: none;
  transition: background-color .2s;

  &:focus {
    outline: none;
  }

  .ctrl-toggle-svg {
    background: transparent;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: left .2s;

    > .ctrl-toggle-svg-checked {
      opacity: 0;
      transition: opacity .2s;
    }

    > .ctrl-toggle-svg-unchecked {
      opacity: 1;
      transition: opacity .2s;
    }
  }

  &.ctrl-toggle-checked .ctrl-toggle-svg {
    left: 18px;

    > .ctrl-toggle-svg-checked {
      opacity: 1;
    }

    > .ctrl-toggle-svg-unchecked {
      opacity: 0;
    }
  }

  &.ctrl-toggle-checked {
    background-color: #006fcc;
  }

  &.ctrl-toggle-disabled,
  &.ctrl-toggle-checked.ctrl-toggle-disabled {
    background-color: #b8b8b8;
    cursor: not-allowed;
  }
}

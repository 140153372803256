.service-group-tree {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 10px;
  .sg-tree {
    overflow: auto;
  }
  .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background: #d9d9d9 !important;
    }
  .ant-tree-node-content-wrapper {
    width: 200px
  }
}

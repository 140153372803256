$checkCirclePath: "../../images/svg-icons/check-circle-solid.svg";

.select-checkbox {
  border: 2px solid #c4c4c4;
  position: relative;
  border-radius: 4px;
  min-height: 100px;
  display: flex;

  .ant-checkbox {
    display: none;
  }

  span {
    padding: 0;
    margin: 0;
    flex: 1;
    overflow: auto;
  }
}
//border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 3s 0.5 linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%2342a5ed' /%3E%3Cstop offset='25%25' stop-color='%2342a5ed' /%3E%3Cstop offset='50%25' stop-color='%2342a5ed' /%3E%3Cstop offset='100%25' stop-color='%2342a5ed' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E %3C/svg%3E") 1;
.select-checkbox-border {
  border: 2px solid #42a5ed;
  transition: 0.5s;

  &:before {
    content: '';
    display: block;
    height: 20px;
    width: 20px;
    top: -(20 / 2)+px;
    left: -(20 / 2)+px;
    background: white;
    background-image: url($checkCirclePath);
    background-repeat: no-repeat;
    border: 3px solid white;
    position: absolute;
  }
}

.simple-setup-progress {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .pan-progress .ant-progress-inner {
    margin: 0;
    padding: 0;
    width: 330px;
    height: 10px;
    box-shadow: none;
    // background: rgba(255, 255, 255, .8);
    background: #fff;

    .ant-progress-bg {
      height: 100% !important;
      // border-radius: 0px !important;
      background: #006fcc;
    }
  }

  .progress-error-header .pan-progress  .ant-progress-inner .ant-progress-bg {
    background: #b8b8b8;
  }

  .setup-inprogress {
    padding-bottom: 12px;
    margin: 0 auto;
    width: 330px;
    position: relative;

    .progress-tooltip.ant-tooltip {
      position: absolute;
      transform: translate(-50%, -33px);
      left: 0;
      opacity: 0;
      transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s; // from antd progress
      max-width: max-content;

      .ant-tooltip-inner {
        white-space: nowrap;
        word-wrap: normal;
      }
    }

    .progress-est-remaining {
      font-weight: bold;
      font-size: 14px;
      line-height: 32px;
      height: 32px;
      margin: 8px auto 23px auto;
      color: #bcf7ff;

      .progress-warn {
        opacity: .75;
        font-size: 12px;
      }
    }

    .email-note {
      font-size: 14px;
      line-height: 17px;
      color: #fff;
      height: 32px;
    }
  }

  .setup-progress-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    transition: opacity .3s ease-in;

    &.loading {
      opacity: 0;
    }

    .url-watermark {
      font-size: 10px;
      line-height: 10px;
      margin-top: 8px;
      color: #bcf7ff;
      opacity: .5;
      height: 0;
      white-space: pre;
      overflow: visible;
    }

    .progress-card {
      width: 450px;
      // min-width: 450px;
      // max-width: 800px;
      text-align: center;
      background: #fff;
      margin: 20px;

      .progress-card-header {
        background: linear-gradient(99.31deg, #38a5ff 4.03%, #85c8ff 85.2%);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
        border-radius: 4px 4px 0 0;
        padding-top: 30px;

        &.progress-error-header {
          background: linear-gradient(99.31deg, #bd5e5e 4.03%, #9c4848 85.2%);
        }

        .progress-card-title,
        .progress-error-message {
          color: #fff;
          // font-family: Roboto;
          letter-spacing: 0em;
          text-align: center;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          text-rendering: optimizeLegibility;
          font-kerning: normal;
        }

        .progress-card-title {
          font-size: 36px;
          line-height: 43px;
          font-weight: 300;
          padding: 20px 15px 15px 15px;
        }

        .progress-error-message {
          font-size: 20px;
          line-height: 22px;
          font-weight: 700;
          max-width: 400px;
          padding: 20px 0;
          margin: 0 auto 20px;

          .progrss-error-code {
            color: #ffc0c0;
          }
        }

        .progress-card-sub-title {
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: center;
          min-height: 32px;
          padding: 15px;
        }

        .btn-contact-support {
          background: #fff;
          border: 1px solid #dadbdb;
          border-radius: 4px;
          font-size: 12px;
          line-height: 14px;
          color: #006fcc;
          text-align: center;
          padding: 8px 16px;
          display: inline-block;
          margin: 30px auto;
        }
      }

      .progress-card-content {
        padding: 15px 10px 10px 10px;
        border-radius: 0 0 4px 4px;
        border: 1px solid #dadbdb;

        .setup-launch-btn {
          position: relative;
          width: 100%;
          background-color: #006fcc;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          height: 40px;
          border-radius: 4px;

          &::after {
            content: '';
            background: url('../../../images/caret-down-white.min.svg') center right no-repeat;
            position: absolute;
            top: 0;
            right: 10px;
            width: 20px;
            height: 100%;
            transform: rotate(-90deg);
          }
        }
      }
    }

    .activate-more-link {
      margin-top: 20px;
      font-size: 14px;
      line-height: 20px;
      color: #006fcc;
    }
  }

  .progress-panw-logo {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    &.panw-logo-waiting > svg {
      transform-origin: center center;
      transform: scale(1);
      animation: pulse-11 2s infinite;
      filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 1));
    }
  }
}

@keyframes pulse-11 {
  0% {
    transform: scale(0.95);
    filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0.7));
  }

  70% {
    transform: scale(1);
    filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, 0));
  }

  100% {
    transform: scale(0.95);
    filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0));
  }
}

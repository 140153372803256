.select-product-wrapper {
  height: 100%;
  .select-logo {
    padding: 10px 0px;
    width: 90px;
    > img {
      width: 60px;
      margin-left: 15px;
    }
  }
  .select-right {
    width: 100%;
    margin: 10px 10px;
  }
  .select-title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
  .select-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }

}

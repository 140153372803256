.batch-error-popover {
  .ant-tooltip-inner {
    font-size: 12px;
    line-height: 16px;
    color: #333333;
    align-items: center;
    background-color: #fafafa;
  }
  .ant-tooltip-arrow {
    border-top-color: #fafafa;
  }
}
.batch-support-btn {
  border-radius: 4px;
  color: #006fcc;
  margin-top: 10px;
  border-color: #fff;
  &:hover, &:visited {
    color: #006fcc;
    border-color: #fff;
    background-color: #fff;
  }
}

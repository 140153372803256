.fls-setup.fls-config.setup-card-body {
  height: 350px;
  padding: 20px 30px;

  .pan-input {
    display: block;
  }
}

.multi-panorama-info {
  padding: 8px;
  background-color: #EFF8FF;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #808080;
  // padding: 9px 0 25px 0;
}

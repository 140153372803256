.setup-card .fls-firewalls.setup-card-body {
  height: 450px;
  padding: 12px;

  .fls-fw-wrapper {
    flex: 1;
    border: 1px solid #eaeaea;
    box-sizing: border-box;

    .fls-fw-search {
      flex: 0;
    }

    .input-search-wrapper {
      padding: 10px;
      border-bottom: 1px solid #eaeaea;
    }

    .fls-fw-table-wrapper {
      flex: 1;
      align-content: flex-start;

      > .ReactTable {
        border: 0;

        .rt-tbody > .rt-tr-group:first-of-type {
          border-top: 0;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #006fcc;
          border-color: #006fcc;
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
          background-color: #006fcc;
        }

        .rt-tr > .rt-td:first-child {
          text-overflow: clip;
        }
      }

      .rt-tr-group {
        border-top: 1px solid #eaeaea;
      }

      .ctrl-toggle {
        margin-right: 8px;
      }

      .toggle-text {
        vertical-align: bottom;
      }

      .ctrl-toggle-disabled + .toggle-text {
        color: #b8b8b8;
      }
    }
  }

  .cert-status {
    &::before {
      content: '';
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      margin: 1px 8px -1px 0;
      background: #b8b8b8;
    }

    &.dot-success::before {
      background: #2cbea2;
    }

    &.dot-warning::before {
      background: #f4bb01;
    }

    &.dot-error::before {
      background: #d13c3c;
    }
  }

  .fls-fw-counter {
    margin: 0 0 8px 0;
    font-size: 12px;
  }

  .fls-info-icon {
    width: 16px;
    height: 16px;
    color: #006fcc;
    display: inline-block;
    margin: 2px 8px -2px;
  }
}

@import "../../../../common";

.fw-subscribed-tbl.ReactTable {
  border: none;

  .rt-tr-group {
    flex: none;
  }

  .rt-thead.-header .rt-th:not(:last-child):after {
    width: 0px;
  }

  .rt-tbody .rt-td {
    border-right: none;
  }
}

@import "../../../../common";
$rightOutlined: "../../../../images/svg-icons/right-outlined.svg";

.fw-wrapper {
  height: 300px;
  padding-right: 12px;

  .fw-models {
    display: flex;
    flex-direction: column;
    flex: 0.05;
    margin-left: 11px;
    margin-bottom: 10px;

    .fw-model-tbl.ReactTable {
      font-size: 12px;
      border: none;
      max-width: 250px;

      .rt-tr-group {
        flex: none;
      }

      .rt-thead.-header .rt-th:not(:last-child):after {
        width: 0px;
      }

      .rt-tbody .rt-td {
        border-right: none;
        padding-right: 0.7rem;

        .dirty-state {
          margin: 1.5px;
          height: 8px;
          background-color: orange;
          width: 8px;
          border-radius: 100%;
        }
      }
    }
  }

  .fw-devices {
    overflow-y: auto;
    flex: 0.995;
    margin-left: 10px;
    position: relative;
    z-index: 1;

    .fw-details-header {
      padding: 12px 5px 0 0;
      color: #000000;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
    }

    .license-header {
      padding: 0 5px 5px 12px;
      font-size: 12px;
    }

    .search-wrapper {
      border: 1px solid #eaeaea;

      .fw-devices-search {
        padding: 5px;
        border-radius: 0;
        border: none;
      }

      .ant-input-search-icon {
        padding: 5px;
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 16px;
        color: #a3a3a3;
      }
    }

    .fw-device-tbl.ReactTable {
      font-size: 12px;

      .rt-tr-group {
        flex: none;

        .rt-td { //select-option
          max-height: 48px;
        }
      }

      .rt-thead.-header .rt-th:not(:last-child):after {
        width: 0px;
      }
    }
  }

  .right-arrow-visible {
    visibility: visible;
    background-image: url($rightOutlined);
    background-repeat: no-repeat;
    margin: auto;
    background-position: center;
  }

  .right-arrow-hidden {
    visibility: hidden;
  }
}

.custom-popup-over {
  width: 30%;
  max-width: 250px;
  line-height: 1.4;
}

.cdl-column-tooltip {

  .ant-popover-arrow {
    background-color: rgba(0, 0, 0, 0.75);
    box-shadow: 0 2px 8px rgb(0 0 0 / 30%);
  }
  .ant-popover-inner {
    background-color: rgba(0, 0, 0, 0.75);
    box-shadow: 0 2px 8px rgb(0 0 0 / 30%);
    .ant-popover-inner-content {
      color: white;
    }
  }

}

.license-select {
  .ant-select-dropdown-menu-item-group-title {
    font-size: 11.5px;
  }
}

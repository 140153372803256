.setup-progress {
  > .flex-box-fill {
    overflow: auto;
  }

  .progress-info {
    .progress-complete-text {
      font-size: 16px;
      font-weight: bold;
    }

    .progress-remain-text {
      font-size: 14px;
      color: #fff;
    }

    .btn-contact-support {
      background: #8f000e;
      color: #fff;
      border: none;
      height: 31px;
    }
  }

  .misc-value {
    margin-left: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .pan-progress {
    .ant-progress-inner {
      border-radius: 0px !important;
      margin: 0;
      padding: 0;
      height: 62px;
      box-shadow: none;
      background: rgba(255, 255, 255, .8);

      .ant-progress-bg {
        height: 100% !important;
        border-radius: 0px !important;
        background: #eff7ff;
      }
    }
  }

  .progress-normal {
    border-bottom: solid 4px #c4c4c4;
  }

  .progress-continue {
    border-bottom: solid 4px #28c2a6;

    .ant-progress-bg {
      background-color: #1f75b2 !important;
    }
  }

  .progress-done {
    border-bottom: solid 4px #28c2a6;

    .ant-progress-bg {
      background: #eff7ff !important;
    }
  }

  .progress-error {
    opacity: 0.9;
    border-bottom: solid 4px #c4c4c4;

    .ant-progress-bg {
      background: #ffefef !important;
    }
  }

  .pan-progress-text {
    width: 100%;
    padding: 0 20px;
  }

  .progress-complete-text {
    color: #fff;
    line-height: 30px;
  }

  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .prisma_header {
    padding-top: 65px;
    height: 106px;
    width: 100%;

    .setup-complete-icon-check {
      height: 79px;
      width: 79px;
      border-radius: 100%;
      background-color: white;
    }

    .divider {
      opacity: 0.1;
      width: 1px;
      border: 1px solid #000000;
      height: 60%;
    }

    .setup_complete {
      text-align: end;
      font-style: normal;
      font-weight: 300;
      font-size: 48px;
      line-height: 34px;
    }

    .user_feedback {
      font-style: normal;
      font-weight: 100;
      font-size: 18px;
      line-height: 26px;
    }
  }

  .setup-progress-main {
    display: flex;
    justify-content: center;
    flex: 1;
    transition: opacity .3s ease-in;

    &.loading {
      opacity: 0;
    }
  }

  .recommended-btn-wrapper {
    background: linear-gradient(0deg, #2686cb 0.89%, #1f75b2 100.89%);

    &.gray-background {
      background: linear-gradient(270deg, rgba(196, 196, 196, 0.1) 0%, rgba(196, 196, 196, 0) 96.41%);
    }

    border: 1px solid #d4d4d4;
    border-radius: 4px;

    .pan-button.ant-btn {
      margin: 2px 0;
      height: 50px;
      line-height: 31px !important; // due to important in button.css
      background: linear-gradient(90deg, rgba(196, 196, 196, 0.1) 0%, rgba(196, 196, 196, 0) 96.41%);
      border: none;

      &:disabled {
        color: white;
        opacity: 0.5;
        border: none;

        &:hover {
          background: linear-gradient(90deg, rgba(196, 196, 196, 0.1) 0%, rgba(196, 196, 196, 0) 96.41%);
          opacity: 0.3;
        }
      }

      > i {
        position: absolute;
        top: 16px;
        right: 10px;
      }
    }

    .gray-color {
      color: #808080;
    }
  }

  .progress-card {
    margin-top: 38px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #d4d4d4;
    margin-bottom: 25px;
    .progress-card-title {
      border-radius: 10px 10px 0 0;
      height: 110px;
      width: 100%;
      background: linear-gradient(180deg, #ffffff 0.89%, #fafafa 100.89%);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      padding: 5px 42px;

      .setup_complete {
        margin: 4px 0;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 32px;
      }

      .user_feedback {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
      }
    }

    .progress-body {
      padding: 30px;
      width: 100%;
    }

    .card-label {
      margin: 10px 0;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      font-style: italic;
      color: #333333;
    }

    .video-tutorial {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      color: #333333;
    }

    .video-title {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      display: block;
    }

    .progress-cards-wrapper {
      margin: 15px 0;
      background: none;
      border: 1px solid #d4d4d4;

      > .ant-collapse-item {
        &:nth-child(odd) {
          background: linear-gradient(90deg, rgba(196, 196, 196, 0.1) 57.75%, rgba(196, 196, 196, 0) 96.41%);
        }

        padding-top: 5px;
        padding-bottom: 30px;
        border: none;

        > .ant-collapse-content {
          border: none;
        }
      }
    }
  }
}

.hb-skeleton {
  display: table;
  width: 100%;

  .hb-content {
    display: table-cell;
    width: 100%;
    vertical-align: top;
  }

  .hb-title {
    height: 16px;
    margin-top: 16px;
    background: #f2f2f2;
  }
  .hb-skeleton-avatar {
    width: 80px;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    list-style: none;
    margin: 24px 10px;
    height: 38px;
    animation: skeleton-loading 1.4s ease infinite;
  }

  .hb-paragraph {
    margin: 24px 10px 0 10px;
    ul, li {
      list-style: none;
      margin: 0;
    }

    > li {
      background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
      background-size: 400% 100%;
      margin: 10px;
      width: 80%;
      height: 16px;
      list-style: none;
      animation: skeleton-loading 1.4s ease infinite;
    }
  }

  @keyframes skeleton-loading {
    0% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0 50%;
    }
  }
}

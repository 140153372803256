@import "../../../common";

.confirm-activation {
  max-height: 400px;
  @extend .scroll-container;
  overflow: auto;

  .ant-list-item {
    border-bottom: none;
  }

  .confirm-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
  }

  .confirmation-misc {
    padding: 10px 0;

    .misc-value {
      margin-left: 10px;
    }

    .expiration {
      color: #54b79f;
    }
  }

  .sub-domain {
    .ant-form-item-label {
      text-transform: none;
      text-align: left;
      ::selection {
        background: none !important;
        color: #333 !important;
      }
    }
  }

  .confirmation-prod-description {
    margin-top: -10px; // forcing to go up. since antd adding some extra margin top
    margin-left: 40px;
    color: rgba(0, 0, 0, 0.45);

    .setup-label {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
    }

    .misc-value {
      font-size: 14px;
      font-weight: normal;
    }
  }
}

.simple-card {
  padding: 14px 21px;
  background: #EFF8FF;
  border: 1px solid #DADBDB;
  border-radius: 4px;
  h4 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 5px;
  }
}

@import "../../../../common";

.fw-check-details-header {
  padding: 8px 12px ;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
}

.fw-total {
  float: right;
}

.fw-search-wrapper {
  border: 1px solid #eaeaea;
  margin: 7px 9px 0 10px;

  .fw-devices-search {
    padding: 8px;
    border-radius: 0;
    border: none;
  }

  .ant-input-search-icon {
    padding: 5px;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 16px;
    color: #a3a3a3;
  }
}

.fw-check-wrapper {
  height: 300px;
  padding-right: 5px;

  .fw-check-devices {
    overflow-y: auto;
    flex: 0.995;
    margin-left: 10px;
    position: relative;
    z-index: 1;
    .fw-device-check-tbl.ReactTable {
      font-size: 12px;
      .rt-thead.-header .rt-th:not(:last-child):after {
        width: 0px;
      }
    }
  }

}

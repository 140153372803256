.register-panorama {
  > * {
    margin-top: 15px;
  }

  .help-list {
    padding-left: 13px;

    li {
      line-height: 22px;
    }
  }
}

.progress-completed-psk > .psk-instruction-block {
  text-align: left;
  color: #333;
  background: #eff8ff;
  padding: 10px;
  margin-top: 16px;
  font-size: 12px;

  .setup-launch-btn + & {
    margin-top: 1em;
  }

  .psk-caption {
    line-height: 24px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .panorama-fw-instrucation > .psk-caption {
    text-transform: unset;
  }

  .psk-input-wrap {
    position: relative;
    margin: 12px 0;

    .psk-label {
      font-size: 13px;
      line-height: 14px;
    }

    .otp-psk-expiration {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 12px;
      line-height: 14px;
      color: #b3b3b3;
      margin-top: 5px;
    }

    .ant-tooltip-inner {
      word-wrap: normal;
      white-space: nowrap;
    }

    .psk-text-input {
      font-size: 12px;
      display: block;
      width: 100%;
      padding: 4px 32px 4px 8px;
      line-height: 20px;
      border: 1px solid #d4d4d4;
      border-radius: 4px;
      outline: none;
    }

    .psk-copy-btn {
      position: absolute;
      right: 0;
      bottom: 0;
      top: auto;
      appearance: none;
      outline: none;
      border: 0;
      background: transparent;
      cursor: pointer;
      height: 30px;
      width: 30px;
    }

    svg.otp-psk-copy-icon {
      fill: #333;
      width: 14px;
    }
  }

  .psk-instrucations {
    margin: 10px 20px 10px 0;
    font-size: 12px;
    line-height: 24px;
    padding-left: 14px;
  }
}

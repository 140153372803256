@import "../../../../common";

.subscription-tooltip {
  .ant-tooltip-inner {
    font-size: 12px;
    line-height: 16px;
    color: #333333;
    align-items: center;
    background-color: #fafafa;
  }

  .ant-tooltip-arrow {
    border-top-color: #fafafa;
  }
}

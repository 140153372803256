@import "../../../common";

.activate-product {
  height: 333px;
  width: 100%;
  padding: 10px 25px;
  margin-bottom: -3px;
  overflow-y: auto;
  @extend .scroll-container;

  .select-checkbox {
    margin: 10px 0;

    .misc-value {
      font-weight: normal;
    }
  }

  .data-lake-note {
    b {
      font-weight: bold;
      color: #54b79f;
    }
    font-style: normal;
    color: #333;
  }
}

.setup-footer {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  justify-self: flex-end;
  padding-top: 10px;

  svg.panw-logo {
    width: 25px;
    height: 18px;
    color: #000;
  }

  .footer-col {
    padding: 2px 10px;

    &,
    a {
      color: rgba(0, 0, 0, .5);
      font-style: normal;
      font-size: 12px;
    }

    a {
      font-weight: bold;
    }
  }
}
